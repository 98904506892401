/* --------------GLOBAL STYLES--------------------- */

html {
	font-size: 100%;
	height: 100%;
	overflow-x: hidden;
	margin: 0px;
	padding: 0px;
	touch-action: manipulation;
}

body {
	font-size: 16px;
	font-family: "Poppins", serif;
	width: 100%;
	height: 100%;
	margin: 0;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-wrap: break-word;
	color: #333;
}
