/* --------------App STYLES--------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins", cursive;
	line-height: 1.3;
	font-weight: inherit;
}

p {
	font-family: "Poppins", cursive;
	line-height: 1.4;
	font-size: 1.05em;
	font-weight: 400;
}

h1 {
	font-size: 4em;
	line-height: 1;
}
h2 {
	font-size: 2.2em;
	line-height: 1.1;
}
h3 {
	font-size: 1.8em;
}
h4 {
	font-size: 1.3em;
}

a,
button {
	display: inline-block;
	text-decoration: none;
	color: inherit;
	line-height: 1;
	transition: all 0.25s ease-in-out;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
	text-decoration: none;
	color: #ffa804;
}

a:hover svg {
	fill: #ffa804;
}

img {
	width: 100%;
}

li {
	list-style: none;
	display: inline-block;
}

.basic-color {
	color: #ffa804;
}

.font-poiret {
	font-family: "Poiret One", "Poppins", cursive;
}

.font-poiret-p {
	font-family: "Poiret One", "Poppins", cursive;
	font-weight: 600;
	font-size: 1.2rem;
}

/* --------------HEADER---------------- */
.intro-section {
	padding-top: 10rem;
	position: relative;
	background-size: cover;
	z-index: 1;
	background: url("../images/background-img.jpg") no-repeat 40% 40%;
}

.intro-section:before {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
}

.intro-section:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 150px;
	z-index: -1;
	background: #fff;
}

.intro {
	padding: 0 25px;
	margin-top: 100px;
	text-align: center;
	background: #fff;
}

.intro .profile-img {
	height: 250px;
	width: 250px;
	border-radius: 150px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	top: -110px;
	margin-bottom: -80px;
}

/*----------------- About ----------------------*/

.about-container hr {
	background-color: #ffa804;
}

.about-container .tech-list-item {
	border: none;
}
/* 
.projects-container {
	background-color: rgba(black, 0.4);
	color: #ffa804;
} */

/* .contact-container {
	background-color: rgba(#37beff, 0.9);
}
.contact-form {
	background: url("/images/berlin.jpeg") no-repeat fixed left;
	background-size: cover;
	border-radius: 1rem;
} */
/* 
.contact-form .form {
	background-color: rgba(black, 0.8);
	border-radius: 1rem;
	margin: 0;
} */

/*----------------- Footer ----------------------*/
.footer {
	color: #fff;
	background-color: #333;
}
